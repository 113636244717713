<template>
  <div>
    <v-row class="match-height">
      <v-col
        cols="12"
      >
        <stat-cards></stat-cards>
      </v-col>
      <v-col
        cols="12"
        md="12"
        sm="12"
        class="pa-2"
        order="1"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    offset-md="9"
                    md="3"
                  >
                    <v-text-field
                      v-model="search"
                      class="pb-3"
                      :append-icon="icons.mdiMagnify"
                      label="Search"
                      single-line
                      hide-details
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-data-table
                  :key="rxTodayKey"
                  v-model="filterStore.selectedDrugs"
                  :loading="loading"
                  dense
                  :search="search"
                  :headers="pharmacyMoleculeListTableHeaders"
                  :items="dataStore.moleculePerMonthTableData"
                  :items-per-page="10"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, 50, 100, -1],
                  }"
                  item-key="genericProductNumber"
                  single-select
                  sort-by="salesSum"
                  sort-desc
                  :item-class="itemClass"
                >
                  <template #[`item.genericName`]="{ item }">
                    <a
                      class="tw-underline text-wrap"
                      style="white-space: normal; word-wrap: break-word;"
                      @click.prevent="goToMoleculeDashboard(item)"
                    >
                      {{ item.genericName }}
                    </a>
                  </template>
                  <template #item.Selected="{ item }">
                    <v-icon
                      v-if="item.genericProductNumber === filterStore.selectedDrugs[0]?.genericProductNumber"
                      color="success"
                      large
                    >
                      {{ icons.mdiCheckBold }}
                    </v-icon>
                  </template>
                  <template #item.target="{ item }">
                    <v-icon
                      v-if="item.target"
                      color="success"
                    >
                      {{ icons.mdiStarCheck }}
                    </v-icon>
                  </template>
                  <template #item.DailyQtyDispenseSum="{ item }">
                    {{ item.DailyQtyDispenseSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                  </template>
                  <template #item.marketShare="{ item }">
                    {{ item.marketShare }}%
                    <v-progress-linear
                      :value="item.marketShare"
                      :color="item.marketShare > 50 ? 'success' : 'error'"
                      height="10"
                    ></v-progress-linear>
                  </template>
                  <template
                    v-for="header in pharmacyMoleculeListTableHeaders.filter(header => header.hasOwnProperty('formatter'))"
                    #[`item.${header.value}`]="{ value }"
                  >
                    {{ header.formatter(value) }}
                  </template>

                  <template slot="body.append">
                    <tr v-if="dataStore.stats">
                      <td></td>
                      <td>
                        <span class="tw-text-xl tw-text-black dark:tw-text-white"> Totals: </span>
                      </td>
                      <td></td>
                      <td>
                        <span class="tw-text-xl tw-text-green-900 dark:tw-text-green-600">
                          {{ currencyConvert(dataStore.stats.totalSalesNum.sumOfSales) }}
                        </span>
                      </td>
                      <td>
                        <span class="tw-text-xl tw-text-green-900 dark:tw-text-green-600">
                          {{ currencyConvert(dataStore.stats.totalSalesNum.sumOfTaroSales) }}
                        </span>
                      </td>
                      <td>
                        <span class="tw-text-xl tw-text-green-900 dark:tw-text-green-600">
                          {{ dataStore.stats.totalSalesNum.marketShare }}
                        </span>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="12"
        sm="12"
        class="pa-2"
        order="4"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <stacked-time-seriesmonthly-mfr
              series="monthlyStackedSalesByMfrTar"
            ></stacked-time-seriesmonthly-mfr>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <mfr-market-share-pie-chart-watch-list></mfr-market-share-pie-chart-watch-list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from 'vue'
import {
    mdiRefresh,
    mdiCached,
    mdiStarCheck,
    mdiCheckBold
} from '@mdi/js'
import { pharmacyMoleculeListTableHeaders } from '@/assets/headers/index'
import { useFilterStore } from '@/stores/FilterStore'
import { roundInt, currentDateTime, currencyConvert } from '@/util/functions'
import { useDataStore } from '@/stores/Data'
import statCards from '@/components/tables/statCards.vue'

export default {
    name: 'PharmacyMoleculeListTable',
    components: {
        statCards,
        stackedTimeSeriesmonthlyMfr: () => import('@/views/molecule/stackedTimeSeriesmonthlyMfr.vue'),
        MfrMarketSharePieChartWatchList: () => import('@/components/charts/MfrMarketSharePieChartWatchList.vue')
    },
    setup() {
        const loading = ref(false)
        const dataStore = useDataStore()
        const selected = ref([])
        const currentTimeStamp = currentDateTime()
        const search = ref('')
        const filterStore = useFilterStore()
        const rxTodayKey = ref(0)
        const selectedMfrSales = ref([])
        dataStore.watchViewFilter()

        return {
            pharmacyMoleculeListTableHeaders,
            currencyConvert,
            loading,
            dataStore,
            selected,
            currentTimeStamp,
            roundInt,
            selectedMfrSales,
            search,
            filterStore,
            rxTodayKey,
            icons: {
                mdiCheckBold,
                mdiStarCheck,
                mdiRefresh,
                mdiCached
            }
        }
    },

    watch: {},

    methods: {
        async handleClick() {
            this.loading = true
            await this.dataStore.filterPharmacyData()
            setTimeout(() => {
                this.loading = false
            }, 1000) // delay of 2 seconds
        },

        goToMoleculeDashboard(item) {
            this.filterStore.setSelectedDrugs([item])
            this.$router.push({ name: 'molecule-dashboard' })
        },
        itemClass(item) {
            return item.genericProductNumber === this.filterStore.selectedDrugs[0]
                ?.genericProductNumber
                ? 'highlight-row'
                : ''
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.highlight-row {
  background-color: #e0f7fa !important;
}
</style>
