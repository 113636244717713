<template>
  <div>
    <v-row class="tw-pb-3">
      <v-col
        cols="12"
        lg="4"
        md="4"
        sm="6"
        order="1"
      >
        <statistics-card-horizontal
          :stat-title="pharmacyGenericPotential.statTitle"
          :change="pharmacyGenericPotential.change"
          :color="pharmacyGenericPotential.color"
          :icon="pharmacyGenericPotential.icon"
          :statistics="dataStore.quickStats.totalGenericPotential.total"
          :currency="true"
        ></statistics-card-horizontal>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        md="4"
        sm="6"
        order="2"
      >
        <statistics-card-horizontal
          :stat-title="pharmacyListStats.statTitle"
          :change="pharmacyListStats.change"
          :color="pharmacyListStats.color"
          :icon="pharmacyListStats.icon"
          :statistics="dataStore.quickStats.totalGenericPotential.selectedMfrSales"
          :currency="true"
        ></statistics-card-horizontal>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        md="4"
        sm="6"
        order="3"
      >
        <statistics-card-horizontal
          :stat-title="selectedMfrMarketShare.statTitle"
          :change="selectedMfrMarketShare.change"
          :color="selectedMfrMarketShare.color"
          :icon="selectedMfrMarketShare.icon"
          :statistics="dataStore.quickStats.totalGenericPotential.selectedMfrMarketShare"
        ></statistics-card-horizontal>
      </v-col>
      <!-- <v-col
        cols="12"
        md="3"
        lg="3"
        sm="6"
        order="4"
      >
        <active-filter></active-filter>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import {
    mdiAccountOutline,
    mdiStorePlus,
    mdiCashMultiple,
    mdiStore,
    mdiFastForwardOutline,
    mdiSackPercent,
    mdiTrendingUp,
    mdiPoll,
    mdiCurrencyUsd,
    mdiAccountPlusOutline,
    mdiMagnify
} from '@mdi/js'
import { pharmacyTableHeaders } from '@/assets/headers/index'
import StatisticsCardHorizontal from '@/@core/components/statistics-card/StatisticsCardHorizontal.vue'
import { useFilterStore } from '@/stores/FilterStore'
import { currencyConvert, handleResize, getCompanySalesName } from '@/util/functions'
import { usePharmacyListStore } from '@/stores/PharmacyListStore'
import { useDataStore } from '@/stores/Data'
import { ref, watch } from 'vue'
import { getVuetify } from '@core/utils'
import { useWindowSize } from '@vueuse/core'

export default {
    name: 'StatCards',
    components: {
        StatisticsCardHorizontal,
    },
    setup() {
        const vuetify = getVuetify()
        const dataStore = useDataStore()
        const filterStore = useFilterStore()
        const companySalesName = getCompanySalesName()
        const pharmacyListStore = usePharmacyListStore()
        const { width, height } = useWindowSize()

        const rowHeight = 50 // adjust this to the actual height of each row

        const pharmacyGenericPotential = {
            statTitle: 'Total Sales',
            statistics: '',
            change: '+',
            icon: mdiPoll,
            color: '#2196F3',
        }

        const pharmacyListStats = {
            statTitle: `${companySalesName} Sales`,
            statistics: '',
            change: '+',
            icon: mdiCurrencyUsd,
            color: '#4CAF50',
        }
        const averagePerPharmacy = {
            statTitle: 'Average Potential Per Pharmacy',
            statistics: '',
            change: '+',
            icon: mdiAccountPlusOutline,
            color: '#9C27B0',
        }

        const averageTaroSales = {
            statTitle: `Average ${companySalesName} Sales Per Pharmacy`,
            statistics: '',
            change: '+',
            icon: mdiTrendingUp,
            color: '#FF9800',
        }

        const selectedMfrMarketShare = {
            statTitle: 'Market Share',
            statistics: '',
            change: '+',
            icon: mdiSackPercent,
            color: 'error',
        }
        const filterData = ref({})
        const search = ref('')

        return {
            filterData,
            currencyConvert,
            rowHeight,
            vuetify,
            width,
            height,
            dataStore,

            pharmacyListStore,
            filterStore,
            pharmacyGenericPotential,
            pharmacyListStats,
            averagePerPharmacy,
            selectedMfrMarketShare,
            handleResize,
            averageTaroSales,
            search,
        }
    },
    data() {
        return {
            showCard: false,
            filterStoreUnsubscribe: null,
            pharmacyTableHeaders,
            rowsPerPage: 10,
            icons: {
                mdiMagnify,
                mdiAccountOutline,
                mdiStorePlus,
                mdiCashMultiple,
                mdiStore,
                mdiFastForwardOutline,
                mdiSackPercent,
                mdiTrendingUp,
                mdiPoll,
                mdiCurrencyUsd,
                mdiAccountPlusOutline
            },
        }
    },
    methods: {
        searchTable(search) {
            this.search = search
        },
        filterByTarget(view) {
            console.log(view)
        },

        updateShowCardProp(showCard) {
            this.showCard = showCard
        },
    },
}
</script>
<style>
  .fit-within-viewport {
    max-width: 100%;
    margin: 0 auto;
  }
</style>
